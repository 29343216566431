import { Auth } from "aws-amplify";
import { updateUserInfo, authStatus } from "../../actions";

import createStore from "../../state/createStore";

export async function authenticateUser(store) {
  console.log("we are authenticating user");
  //
  const state = store.getState();
  console.log(store);
  if (state.auth.isAuthenticated === false) {
    try {
      const creds = await Auth.currentCredentials();
      console.log("creds");
      console.log(creds);
      let user = await Auth.currentAuthenticatedUser();
      console.log("user");
      console.log(user);
      const userInfo = await Auth.currentSession();
      console.log("userInfo");
      console.log(userInfo);

      if (userInfo) {
        let user = await Auth.currentAuthenticatedUser();
        userLogIn(user, store.dispatch);
      }
    } catch (error) {
      console.log(error);
    }
  }
  if (state.auth.hasCheckedAuth === false) {
    store.dispatch(authStatus({ hasCheckedAuth: true }));
  }

  return;
}

export async function userLogIn(user_data, dispatch) {
  var user = user_data;
  if (user_data.attributes === undefined) {
    user = await Auth.currentAuthenticatedUser();
  }
  dispatch(
    updateUserInfo({
      practice_id: user.attributes["custom:Practice"],
      first_name: user.attributes["custom:first_name"],
      surname: user.attributes["custom:surname"],
      groups: user.signInUserSession.idToken.payload["cognito:groups"],
      email: user.attributes.email,
    })
  );
  dispatch(authStatus({ isAuthenticated: true }));
  return;
}
