 import "./src/styles/tailwind.css"
// import "semantic-ui-css/semantic.min.css";
import wrapWithProvider from "./wrap-with-provider";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import config from "./src/aws-exports";

Amplify.configure(config);

export const wrapRootElement = wrapWithProvider;
