/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "eu-west-2",
    aws_cognito_identity_pool_id:
      "eu-west-2:cb16c636-46d8-4cac-98be-b7df197ec43f",
    aws_cognito_region: "eu-west-2",
    aws_user_pools_id: "eu-west-2_YsftPLtrP",
    aws_user_pools_web_client_id: "1h2tch5qjj5akbnu80erb93jg7",
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    aws_appsync_graphqlEndpoint:
      "https://ofhlwdbvyfcith4n2u4lnv6nym.appsync-api.eu-west-2.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_apiKey: "da2-6ougqz2cjrhg3ctywnlscowwqq",
    cookieStorage: {
      domain:
        process.env.NODE_ENV === "development" ? "localhost" : ".gpwidget.com", //localhost:8000
      secure: !(process.env.NODE_ENV === "development"),
      path: "/",
      expires: 365,
    },
    aws_cloud_logic_custom: [
      {
        name: "AdminQueries",
        endpoint: "https://dd9haw3hzd.execute-api.eu-west-2.amazonaws.com/prod",
        region: "eu-west-2",
      },
    ],
  };
  
  export default awsmobile;
  