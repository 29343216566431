const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_QUESTIONS":
      return action.payload;
    case "ADD_QUESTION":
      console.log("Adding practice in redux");
      return [...state, action.payload];
    case "REMOVE_QUESTION":
      console.log("Removing practice in redux");
      return state.filter((item) => item.id !== action.payload);
    default:
      return state;
  }
};
