/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGuide = /* GraphQL */ `
  query GetGuide($id: ID!) {
    getGuide(id: $id) {
      id
      type
      name
      title
      text
      description
      data
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const listGuides = /* GraphQL */ `
  query ListGuides(
    $filter: ModelGuideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        title
        text
        description
        data
        data2
        data3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStat = /* GraphQL */ `
  query GetStat($id: ID!) {
    getStat(id: $id) {
      id
      object_id
      user_id
      action
      metric
      data
      createdAt
      updatedAt
    }
  }
`;
export const listStats = /* GraphQL */ `
  query ListStats(
    $filter: ModelStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        object_id
        user_id
        action
        metric
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      staff_member
      form_data
      createdAt
      updatedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        staff_member
        form_data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPractice = /* GraphQL */ `
  query GetPractice($id: ID!) {
    getPractice(id: $id) {
      id
      name
      live
      createdAt
      updatedAt
    }
  }
`;
export const listPractices = /* GraphQL */ `
  query ListPractices(
    $filter: ModelPracticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPractices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        live
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_id
      access
      practice
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        access
        practice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const statByObjectID = /* GraphQL */ `
  query StatByObjectID(
    $object_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StatByObjectID(
      object_id: $object_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        object_id
        user_id
        action
        metric
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const statByUserID = /* GraphQL */ `
  query StatByUserID(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StatByUserID(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        object_id
        user_id
        action
        metric
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
