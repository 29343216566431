import {
  applyMiddleware,
  compose,
  createStore as reduxCreateStore,
} from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   reducers,
//   composeEnhancers(applyMiddleware(thunk))
// );

const loadDevTools = () =>
  process.env.NODE_ENV === "development" && window.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f;

const createStore = () =>
  reduxCreateStore(reducers, compose(applyMiddleware(thunk), loadDevTools()));
export default createStore;
