import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";

import {
  createGuide,
  createTesting,
  deleteGuide,
  updateGuide,
  createPractice,
  deletePractice,
  createFlow,
  deleteFlow,
  createQuestion,
  deleteQuestion,
  createAudit,
  deleteAudit,
  createOption,
  deleteOption,
  createSubmission,
  deleteSubmission,
  createStat,
  deleteStat,
} from "../graphql/mutations";
import {
  listGuides,
  listPractices,
  listFlows,
  questionsByFlowId,
  auditsByPracticeId,
  listOptions,
  submissionsByAuditId,
  listStats,
  listSubmissions,
} from "../graphql/queries";
import { ConsoleLogger } from "@aws-amplify/core";

export const authStatus = (data) => {
  console.log("updating log in details");
  return {
    type: "LOG_IN",
    payload: data,
  };
};

export const updateUserInfo = (data) => {
  return {
    type: "UPDATE_USER_INFO",
    payload: data,
  };
};

export const updateNavigation = (data) => {
  return {
    type: "UPDATE_NAVIGATION",
    payload: data,
  };
};

////// DATA

export const updateInitialFLowAuditData = (data) => {
  return {
    type: "INITIAL_DATA_UPDATE",
    payload: data,
  };
};

export const updateSankeyData = (data) => {
  return {
    type: "UPDATE_SANKEY_DATA",
    payload: data,
  };
};

//////  GUIDE DATA   //////

export const addGuide = (data) => {
  console.log("Adding Guide");
  console.log(data);
  return async (dispatch) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createGuide, { input: data })
      );
      console.log(result);
      dispatch({
        type: "ADD_GUIDE",
        payload: result.data.createGuide,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchGuides = () => {
  console.log("fetching guides");
  return async (dispatch) => {
    try {
      const response = await API.graphql(graphqlOperation(listGuides));
      dispatch({
        type: "FETCH_GUIDES",
        payload: response.data.listGuides.items,
      });
      return;
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const removeGuide = (id) => {
  console.log("removing...");
  console.log(id);
  return async (dispatch) => {
    try {
      const result = await API.graphql(
        graphqlOperation(deleteGuide, { input: { id: id } })
      );
      console.log(result.data.deleteGuide.id);
      dispatch({
        type: "REMOVE_GUIDE",
        payload: result.data.deleteGuide.id,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const update_Guide = (data) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateGuide, { input: data })
      );
      console.log(response);
      dispatch({
        type: "UPDATE_GUIDE",
        payload: response.data.updateGuide,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

// //////  FLOW DATA   //////

export const addFlow = (data) => {
  return async (dispatch) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createFlow, { input: data })
      );
      dispatch({
        type: "ADD_FLOW",
        payload: result.data.createFlow,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchFlows = () => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(graphqlOperation(listFlows));
      console.log(response);
      dispatch({
        type: "FETCH_FLOWS",
        payload: response.data.listFlows.items,
      });
      return;
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const removeFlow = (data) => {
  return async (dispatch) => {
    const result = await API.graphql(
      graphqlOperation(deleteFlow, { input: { id: data.id } })
    );
    dispatch({
      type: "REMOVE_FLOW",
      payload: result.data.deleteFlow.id,
    });
  };
};

//////  QUESTION DATA   //////

export const addQuestion = (data) => {
  console.log("adding question");
  return async (dispatch) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createQuestion, { input: data })
      );
      dispatch({
        type: "ADD_QUESTION",
        payload: result.data.createQuestion,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchQuestions = (id) => {
  return async (dispatch) => {
    try {
      // const response = await API.graphql({
      //   query: questionsByFlowId,
      //   variables: { flow_id: id },
      //   authMode: "AWS_IAM",
      // });

      console.log("fetching questions wit id..." + id);
      const response = await API.graphql({
        query: questionsByFlowId,
        variables: { flow_id: id },
      });
      const ordered_questions = _.sortBy(
        response.data.QuestionsByFlowId.items,
        "index_number"
      );
      console.log("ordered_questions");
      console.log(ordered_questions);
      dispatch({
        type: "FETCH_QUESTIONS",
        payload: _.sortBy(
          response.data.QuestionsByFlowId.items,
          "index_number"
        ),
      });
      return;
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const removeQuestion = (data) => {
  return async (dispatch) => {
    const result = await API.graphql(
      graphqlOperation(deleteQuestion, { input: { id: data.id } })
    );
    dispatch({
      type: "REMOVE_QUESTION",
      payload: result.data.deleteQuestion.id,
    });
  };
};

//////  Audit DATA   //////

export const addAudit = (data) => {
  console.log("adding audit");
  console.log(data);
  return async (dispatch) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createAudit, { input: data })
      );
      dispatch({
        type: "ADD_AUDIT",
        payload: result.data.createAudit,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAudits = (id) => {
  console.log("trying to get audits with this id..." + id);
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(auditsByPracticeId, { practice_id: id })
      );
      console.log("response");
      console.log(response);
      dispatch({
        type: "FETCH_AUDITS",
        payload: response.data.AuditsByPracticeId.items,
      });
      return;
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const removeAudit = (data) => {
  return async (dispatch) => {
    const result = await API.graphql(
      graphqlOperation(deleteAudit, { input: { id: data.id } })
    );
    dispatch({
      type: "REMOVE_AUDIT",
      payload: result.data.deleteAudit.id,
    });
  };
};

//////  Options DATA   //////

export const addOption = (data) => {
  console.log("adding Option");
  return async (dispatch) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createOption, { input: data })
      );
      dispatch({
        type: "ADD_OPTION",
        payload: result.data.createOption,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchOptions = (id) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(graphqlOperation(listOptions));
      console.log("response");
      console.log(response);
      dispatch({
        type: "FETCH_OPTIONS",
        payload: response.data.listOptions.items,
      });
      return;
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const removeOption = (data) => {
  return async (dispatch) => {
    const result = await API.graphql(
      graphqlOperation(deleteOption, { input: { id: data.id } })
    );
    dispatch({
      type: "REMOVE_OPTION",
      payload: result.data.deleteOption.id,
    });
  };
};

//////  Submissions DATA   //////

export const addSubmission = (data) => {
  console.log("adding Submission");
  return async (dispatch) => {
    // const result = await API.graphql({
    //   query: createSubmission,
    //   variables: { input: data },
    //   authMode: "AWS_IAM",
    // });
    const result = await API.graphql({
      query: createSubmission,
      variables: { input: data },
    });
    console.log(result);
    dispatch({
      type: "ADD_SUBMISSION",
      payload: result.data.createSubmission,
    });
  };
};

export const fetchAllSubmissions = async () => {
  var data_array = [];
  try {
    var data_array = [];
    const list = await API.graphql(graphqlOperation(listSubmissions));
    // console.log("list display here");
    // console.log(list);
    data_array.push(list.data.listSubmissions.items);
    var token = list.data.listSubmissions.nextToken;
    if (list.data.listSubmissions.nextToken) {
      for (let complete = false; complete === false; ) {
        var new_data = await API.graphql(
          graphqlOperation(listSubmissions, {
            nextToken: token,
          })
        );
        data_array.push(new_data.data.listSubmissions.items);
        token = new_data.data.listSubmissions.nextToken;
        if (new_data.data.listSubmissions.nextToken === null) {
          complete = true;
        }
      }
    }
    const flattenArray = _.flattenDeep(data_array);
    console.log("flattenArray");
    return flattenArray;
  } catch (err) {
    console.log("error fetching data...", err);
  }
};

export const fetchSubmissions = (id) => {
  var data_array = [];

  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(submissionsByAuditId, { audit_id: id })
      );
      data_array.push(response.data.SubmissionsByAuditId.items);
      var token = response.data.SubmissionsByAuditId.nextToken;

      if (response.data.SubmissionsByAuditId.nextToken) {
        for (let complete = false; complete === false; ) {
          var new_data = await API.graphql(
            graphqlOperation(submissionsByAuditId, {
              audit_id: id,
              nextToken: token,
            })
          );
          data_array.push(new_data.data.SubmissionsByAuditId.items);
          token = new_data.data.SubmissionsByAuditId.nextToken;
          if (new_data.data.SubmissionsByAuditId.nextToken === null) {
            complete = true;
          }
        }
      }

      const flattenArray = _.flattenDeep(data_array);
      console.log("flattenArray");
      console.log(flattenArray);

      dispatch({
        type: "FETCH_SUBMISSIONS",
        payload: flattenArray,
      });
      return;
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const removeSubmission = (data) => {
  return async (dispatch) => {
    const result = await API.graphql(
      graphqlOperation(deleteSubmission, { input: { id: data.id } })
    );
    dispatch({
      type: "REMOVE_SUBMISSION",
      payload: result.data.deleteSubmission.id,
    });
  };
};

//////  PRACTICE DATA   //////

export const addPractice = (data) => {
  console.log("we are adding practice");
  return async (dispatch) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createPractice, { input: data })
      );
      console.log("result");
      console.log(result);
      dispatch({
        type: "ADD_PRACTICE",
        payload: result.data.createPractice,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchPractices = () => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(graphqlOperation(listPractices));
      console.log(response);
      dispatch({
        type: "FETCH_PRACTICES",
        payload: response.data.listPractices.items,
      });
      return;
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const removePractice = (data) => {
  return async (dispatch) => {
    const result = await API.graphql(
      graphqlOperation(deletePractice, { input: { id: data.id } })
    );
    dispatch({
      type: "REMOVE_PRACTICE",
      payload: result.data.deletePractice.id,
    });
  };
};
//////  Stat DATA   //////

export const fetchStats = () => {
  console.log("fetching stats");
  return async (dispatch) => {
    try {
      // const response = await API.graphql({
      //   query: listGuides,
      //   authMode: "AMAZON_COGNITO_USER_POOLS",
      // });
      const response = await API.graphql(graphqlOperation(listStats));
      console.log(response.data.listStats.items);
      dispatch({
        type: "FETCH_STATS",
        payload: response.data.listStats.items,
      });
      // return;
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const addStat = (data) => {
  console.log("we are adding a stat");
  return async (dispatch) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createStat, { input: data })
      );
      console.log(result);
      dispatch({
        type: "ADD_STAT",
        payload: result.data.createStat,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const removeStat = (id) => {
  console.log("removing Stat with ID asdlaslkjd" + id);
  return async (dispatch) => {
    console.log("hello");
    const result = await API.graphql(
      graphqlOperation(deleteStat, { input: { id: id } })
    );
    console.log(result);
  };
};
