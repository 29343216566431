/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGuide = /* GraphQL */ `
  mutation CreateGuide(
    $input: CreateGuideInput!
    $condition: ModelGuideConditionInput
  ) {
    createGuide(input: $input, condition: $condition) {
      id
      type
      name
      title
      text
      description
      data
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const updateGuide = /* GraphQL */ `
  mutation UpdateGuide(
    $input: UpdateGuideInput!
    $condition: ModelGuideConditionInput
  ) {
    updateGuide(input: $input, condition: $condition) {
      id
      type
      name
      title
      text
      description
      data
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const deleteGuide = /* GraphQL */ `
  mutation DeleteGuide(
    $input: DeleteGuideInput!
    $condition: ModelGuideConditionInput
  ) {
    deleteGuide(input: $input, condition: $condition) {
      id
      type
      name
      title
      text
      description
      data
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const createStat = /* GraphQL */ `
  mutation CreateStat(
    $input: CreateStatInput!
    $condition: ModelStatConditionInput
  ) {
    createStat(input: $input, condition: $condition) {
      id
      object_id
      user_id
      action
      metric
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateStat = /* GraphQL */ `
  mutation UpdateStat(
    $input: UpdateStatInput!
    $condition: ModelStatConditionInput
  ) {
    updateStat(input: $input, condition: $condition) {
      id
      object_id
      user_id
      action
      metric
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteStat = /* GraphQL */ `
  mutation DeleteStat(
    $input: DeleteStatInput!
    $condition: ModelStatConditionInput
  ) {
    deleteStat(input: $input, condition: $condition) {
      id
      object_id
      user_id
      action
      metric
      data
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      staff_member
      form_data
      createdAt
      updatedAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      staff_member
      form_data
      createdAt
      updatedAt
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      staff_member
      form_data
      createdAt
      updatedAt
    }
  }
`;
export const createPractice = /* GraphQL */ `
  mutation CreatePractice(
    $input: CreatePracticeInput!
    $condition: ModelPracticeConditionInput
  ) {
    createPractice(input: $input, condition: $condition) {
      id
      name
      live
      createdAt
      updatedAt
    }
  }
`;
export const updatePractice = /* GraphQL */ `
  mutation UpdatePractice(
    $input: UpdatePracticeInput!
    $condition: ModelPracticeConditionInput
  ) {
    updatePractice(input: $input, condition: $condition) {
      id
      name
      live
      createdAt
      updatedAt
    }
  }
`;
export const deletePractice = /* GraphQL */ `
  mutation DeletePractice(
    $input: DeletePracticeInput!
    $condition: ModelPracticeConditionInput
  ) {
    deletePractice(input: $input, condition: $condition) {
      id
      name
      live
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      user_id
      access
      practice
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      user_id
      access
      practice
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      user_id
      access
      practice
      createdAt
      updatedAt
    }
  }
`;
