const INITIAL_STATE = {
  data: [{}],
  updated: false,
  pie_data: [],
  pie_data_processed: false,
  sankey_data: [],
  sankey_data_processed: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INITIAL_DATA_UPDATE':
      return { ...state, data: action.payload, updated: true }
      case 'UPDATE_SANKEY_DATA':
        console.log("UPDATING DATA")
        return { ...state, sankey_data_processed: true, sankey_data: action.payload }
    default:
      return state;
  }
};
