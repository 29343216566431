import React from "react";
import { Provider } from "react-redux";
import createStore from "./src/state/createStore";
import { Amplify, Auth, Cache } from "aws-amplify";
import { authStatus, updateUserInfo } from "./src/actions";
import config from "./src/aws-exports";
import { authenticateUser } from "./src/functions/auth";

async function checkUser(store) {
  const state = store.getState();
  console.log(`checkUser state`, state);
  await authenticateUser(store);
  store.dispatch(authStatus({ hasCheckedAuth: true }));
}

export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  Auth.configure(config);
  // Amplify.Logger.LOG_LEVEL = "DEBUG";
  const store = createStore();
  checkUser(store);
  return <Provider store={store}>{element}</Provider>;
};
