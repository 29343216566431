const INITIAL_STATE = {
  hasCheckedAuth: false,
  isAuthenticated: false,
  isAuthenticating: false,
  isLoading: false,
  practice: { id: null, name: null },
  practice_id: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOG_IN":
      return { ...state, ...action.payload };
    case "UPDATE_USER_INFO":
      console.log("action.payload");
      console.log(action.payload);
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
