const INITIAL_STATE = "home"

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_NAVIGATION':
      return action.payload
    default:
      return state;
  }
};
