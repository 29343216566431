const INITIAL_STATE = [
  {
    first_name: null,
    surname: null,
    email: null,
    practice_id: null,
    groups: null,
  },
];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOG_IN":
      return { ...state, ...action.payload };
    case "UPDATE_USER_INFO":
      console.log("action.payload");
      console.log(action.payload);
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
