import { combineReducers } from "redux";
import authReducer from "./authReducer";
import guideReducer from "./guidesReducer";
import statsReducer from "./statsReducer";
import flowAuditReducer from "./flowAuditReducer";
import navigationReducer from "./navigationReducer";
import practiceReducer from "./practiceReducer";
import flowReducer from "./flowReducer";
import questionReducer from "./questionReducer";
import auditReducer from "./auditReducer";
import optionReducer from "./optionReducer";
import submissionReducer from "./submissionReducer";
import userReducer from "./userReducer";

export default combineReducers({
  user: userReducer,
  auth: authReducer,
  guides: guideReducer,
  flowAudit: flowAuditReducer,
  navigation: navigationReducer,
  practices: practiceReducer,
  flows: flowReducer,
  questions: questionReducer,
  audits: auditReducer,
  options: optionReducer,
  submissions: submissionReducer,
  stats: statsReducer,
});
